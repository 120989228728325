import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { array, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { isBookingProcess, resolveLatestProcessName } from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2, H3, H4 } from '../../components';

import css from './OrderPanel.module.css';
import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';
import { useConfiguration } from '../../context/configurationContext';
import { budgetOptions } from '../../util/constants';

const QuoteForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ '../QuoteForm/QuoteForm')
);
const CarouselRequestBookingForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ '../QuoteForm/CarouselRequestBookingForm')
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    variant,
    rootClassName,
    className,
    titleClassName,
    listing,
    transaction,
    lineItemUnitType: lineItemUnitTypeMaybe,
    initialValues,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    history,
    onSubmitInquiry,
    sendInquiryInProgress,
    location,
    intl,

    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = props;

  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const unitType = listing?.attributes?.publicData?.unitType;
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;

  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const {
    publicData = {},
  } = listing.attributes;

  const { budget, displayAddress } = publicData;
  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        className={css.orderPanelModal}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.cardBanner}>
          <div className={css.modalHeading}>
            <H1 className={css.heading}>{title}</H1>
          </div>

          <div className={css.orderHeading}>
            <H3 className={titleClasses}>{budgetOptions.find(b => b.key === budget)?.label}</H3>
          </div>
          <div className={css.orderHeadingAddress}>
            <H4 className={titleClasses}> {displayAddress}</H4>
          </div>

          {price ? (
            <div className={css.priceContainer}>
              <p className={css.price}>{formatMoney(intl, price)}</p>
              <div className={css.perUnit}>
                <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
              </div>
            </div>
          ) : null}

          <div className={css.author}>
            <AvatarSmall user={author} className={css.providerAvatar} />
            <span className={css.providerNamePlain}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
            </span>
          </div>
        </div>

        <div className={css.modalContents}>
          {variant === 'bid' ? (
            <QuoteForm
              isOwnListing={isOwnListing}
              inProgress={sendInquiryInProgress}
              listing={listing}
              initialValues={initialValues}
              onSubmitInquiry={onSubmitInquiry}
            />
          ) : variant === 'quote' ? (
            <CarouselRequestBookingForm
              inProgress={sendInquiryInProgress}
              isOwnListing={isOwnListing}
              listing={listing}
              onSubmitInquiry={onSubmitInquiry}
            />
          ) : variant === 'order' ? (
            <ProductOrderForm
              transaction={transaction}
              formId="OrderPanelProductOrderForm"
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              currentStock={currentStock}
              pickupEnabled={pickupEnabled}
              shippingEnabled={shippingEnabled}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
            />
          ) : null}
        </div>
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <div className={css.priceContainerInCTA}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnitInCTA}>
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </div>
        </div>

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
            disabled={isOutOfStock}
          >
            {isBooking ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
            ) : isOutOfStock ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
            ) : (
              <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
            )}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  marketplaceName: string.isRequired,
  variant: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
