import * as React from 'react';
const InfoIcon = props => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476612 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 9.55546 21.7155 8.12506 21.1627 6.79048C20.6099 5.4559 19.7996 4.24327 18.7782 3.22182C17.7567 2.20038 16.5441 1.39013 15.2095 0.837325C13.8749 0.284523 12.4445 0 11 0ZM12.1 15.4C12.1 15.6917 11.9841 15.9715 11.7778 16.1778C11.5715 16.3841 11.2917 16.5 11 16.5C10.7083 16.5 10.4285 16.3841 10.2222 16.1778C10.0159 15.9715 9.9 15.6917 9.9 15.4V9.9C9.9 9.60826 10.0159 9.32847 10.2222 9.12218C10.4285 8.91589 10.7083 8.8 11 8.8C11.2917 8.8 11.5715 8.91589 11.7778 9.12218C11.9841 9.32847 12.1 9.60826 12.1 9.9V15.4ZM11 7.7C10.7824 7.7 10.5698 7.63548 10.3889 7.51461C10.208 7.39374 10.067 7.22195 9.98374 7.02095C9.90048 6.81995 9.8787 6.59878 9.92114 6.3854C9.96358 6.17202 10.0683 5.97602 10.2222 5.82218C10.376 5.66834 10.572 5.56358 10.7854 5.52113C10.9988 5.47869 11.22 5.50047 11.421 5.58373C11.622 5.66699 11.7937 5.80798 11.9146 5.98887C12.0355 6.16976 12.1 6.38244 12.1 6.6C12.1 6.89174 11.9841 7.17153 11.7778 7.37782C11.5715 7.58411 11.2917 7.7 11 7.7Z"
      fill="#00C3A0"
    />
  </svg>
);
export default InfoIcon;
