import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NotificationBadge,
} from '../../../components';

// import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { ensureCurrentUser } from '../../../util/data';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import IconCollection from '../../IconCollection/IconCollection';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    hideSearchBar,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;  
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const user = ensureCurrentUser(currentUser);
  const { userListingId, userType } = user.attributes.profile.publicData || {};
  const isInstaller = userType === 'IN';
  const isHomeOwner = userType === 'HO';

  const classes = classNames(rootClassName || css.root, className);

  const search = !hideSearchBar && (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <NotificationBadge count={notificationCount} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ status: 'pending' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          {isHomeOwner && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const completeInstallerProfile =
    isAuthenticatedOrJustHydrated && isInstaller && !userListingId ? (
      <NamedLink name="InstallerProfile" className={css.signupLink}>
        <span className={css.signup}>
          <FormattedMessage id="TopbarDesktop.completeProfile" />
        </span>
      </NamedLink>
    ) : null;
  const voltsBalanceLink =
    isAuthenticatedOrJustHydrated && isInstaller ? (
      <NamedLink name="PurchaseAcornsPage" className={css.signupLink}>
        <span className={css.signup}>
        <IconCollection name="VOLT_ICON" />
          <FormattedMessage
            id="TopbarDesktop.volts"
            values={{ balance: currentUser?.attributes.profile.publicData?.voltsBalance || 0 }}
          />
        </span>
      </NamedLink>
    ) : null;
  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const startNewRequest =
    isAuthenticatedOrJustHydrated && isHomeOwner ? (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    ) : null;
  const addInstaller = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" to={{ search: '?userType=IN' }} className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.createInstaller" />
      </span>
    </NamedLink>
  );
  const addHomeOwner = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" to={{ search: '?userType=HO' }} className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.createHomeOwner" />
      </span>
    </NamedLink>
  )
  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {userType === 'IN' ? search : null}
      <div className={css.rightSideBox}>
        {completeInstallerProfile}
        {startNewRequest}
        {inboxLink}
        {voltsBalanceLink}
        {profileMenu}
        {addInstaller}
        {addHomeOwner}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
