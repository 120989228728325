import _ from 'lodash';
import { getTransaction, getTransactions, sendBid, updateTxn } from '../../util/api';
import { types } from '../../util/sdkLoader';
import { showListing } from '../ListingPage/ListingPage.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { transactionEnum } from '../../util/constants';
import { parse } from '../../util/urlHelpers';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';

export const FETCH_TRANSACTIONS_REQUEST = 'app/InboxPagePending/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/InboxPagePending/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/InboxPagePending/FETCH_TRANSACTIONS_ERROR';

export const UPDATE_TRANSACTION_REQUEST = 'app/InboxPagePending/UPDATE_TRANSACTION_REQUEST';
export const UPDATE_TRANSACTION_SUCCESS = 'app/InboxPagePending/UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_ERROR = 'app/InboxPagePending/UPDATE_TRANSACTION_ERROR';

export const FETCH_TRANSACTION_SUCCESS = 'app/InboxPagePending/FETCH_TRANSACTION_SUCCESS';
export const BID_REQUEST = '/InboxPagePending/BID_REQUEST';

const { UUID } = types;

// ================ Reducer ================ //

const initialState = {
  unreads: 0,
  fetchInProgress: false,
  bidInProgress: false,
  updateInProgress: false,
  updateError: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactions: [],
  transaction: {},
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case BID_REQUEST:
      return { ...state, bidInProgress: payload };
    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: payload, fetchOrdersOrSalesError: null };
    case FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case UPDATE_TRANSACTION_REQUEST:
      return { ...state, updateInProgress: payload, updateError: null };
    case UPDATE_TRANSACTION_ERROR:
      return { ...state, updateInProgress: false, updateError: payload };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: _.uniqBy([...state.transactions, payload], '_id'),
        transaction: { ...state.transaction, ...payload },
      };
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = payload => ({ type: FETCH_TRANSACTIONS_REQUEST, payload });
const fetchTransactionsSuccess = payload => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload,
});
const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

const updateTransactionRequest = payload => ({ type: UPDATE_TRANSACTION_REQUEST, payload });

const updateTransactionError = e => ({
  type: UPDATE_TRANSACTION_ERROR,
  payload: e,
});

const fetchTransactionSuccess = payload => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload,
});

const bidRequest = payload => ({ type: BID_REQUEST, payload });
// ================ Thunks ================ //

export const updateTransaction = (_id, params = {}) => dispatch => {
  dispatch(updateTransactionRequest(params.status || true));

  return updateTxn({ _id, params })
    .then(transaction => {
      const listingRef = new UUID(transaction.listingId);
      Object.assign(transaction, { listingRef });
      dispatch(fetchTransactionSuccess(transaction));
      dispatch(updateTransactionRequest(false));
    })
    .catch(e => dispatch(updateTransactionError(e)));
};

export const bid = params => (dispatch, getState) => {
  const { currentUser } = getState().user;

  dispatch(bidRequest(true));
  return sendBid(params)
    .then(transaction => {
      const listingRef = new UUID(transaction.listingId);
      Object.assign(transaction, { listingRef });
      dispatch(fetchTransactionSuccess(transaction));
      return dispatch(
        updateProfile({
          publicData: {
            voltsBalance:
              currentUser.attributes.profile.publicData.voltsBalance - params.bidResponse.noOfVolts,
          },
        })
      );
    })
    .then(() => bidRequest(false))
    .catch(e => bidRequest(false));
};

export const addMessage = params => (dispatch, getState) => {
  const { transaction: _id } = params;

  const { transaction } = getState().InboxPagePending;

  // currently user is in different chatbox
  if (transaction._id !== _id) return Promise.resolve();

  transaction.chats = [...(transaction.chats || []), params];
  dispatch(fetchTransactionSuccess(transaction));
};

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search, config) => (dispatch, getState) => {
  dispatch(fetchTransactionsRequest(true));
  const { page = 1 } = parse(search);
  const { status } = params;

  const statusQueryEnum = {
    [transactionEnum.PENDING]: {
      $in: [transactionEnum.PENDING, transactionEnum.REQUESTED, transactionEnum.FREEZED],
    },
    [transactionEnum.ACCEPTED]: {
      $in: [
        transactionEnum.ACCEPTED,
        transactionEnum.PENDING_PAYMENT,
        transactionEnum.CONFIRM_PAYMENT,
        transactionEnum.SUCCESSFUL_PAYMENT,
        transactionEnum.FAILED_PAYMENT,
        transactionEnum.COMPLETED,
      ],
    },
  };

  if (params.id) {
    return getTransaction({ id: params.id })
      .then(transaction => {
        const listingRef = new UUID(transaction.listingId);
        Object.assign(transaction, { listingRef });
        dispatch(fetchTransactionSuccess(transaction));
        return dispatch(showListing(listingRef, config));
      })
      .then(() => {
        dispatch(fetchTransactionsRequest(false));
      })
      .catch(e => dispatch(fetchTransactionsError(e)));
  }
  if (!statusQueryEnum[status]) return Promise.reject({ error: 'Invalid Status' });
  dispatch(fetchCurrentUserNotifications());
  return getTransactions({
    query: { status: statusQueryEnum[status] },
    options: { page, perPage: INBOX_PAGE_SIZE },
  })
    .then(response => {
      dispatch(fetchTransactionsSuccess(response));
      dispatch(fetchTransactionsRequest(false));
    })
    .catch(e => dispatch(fetchTransactionsError(e)));
};
