import React from 'react';
import Select from 'react-select';
import css from './FieldMultiSelect.module.css';
import ValidationError from '../ValidationError/ValidationError';

const FieldMultiSelect = ({ meta, ...props }) => {
  const { id, name, label } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--marketplaceColor)' : 'white', // Change color on hover
      color: state.isFocused ? 'white' : 'black', // Change text color on hover
    }),
  };

  return (
    <div className={css.field} >
      {label && (id || name) ? <label htmlFor={id || name} >{label}</label> : null}
      <Select styles={customStyles} {...props}  />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

export default FieldMultiSelect;
