import generac from '../assets/generac.png';
import briggs from '../assets/briggs.png';
import champion from '../assets/champion.png';
import honeyWell from '../assets/honeyWell.png';
import kohler from '../assets/kohler.png';
import championLogo from '../assets/championLogo.png';
import generacLogo from '../assets/generacLogo.png';
import honeyWellLogo from '../assets/honeywellLogo.png';
import kohlerLogo from '../assets/kohlerLogo.png';
import briggsLogo from '../assets/briggsLogo.png';

export const transactionEnum = {
  PENDING: 'pending', // a proposal sent to company
  REQUESTED: 'requested', // company bid on proposal & sent to homeowner
  DECLINED: 'declined', // proposal is declined by homeowner
  DECLINED_BY_INS: 'declined_by_ins',
  ACCEPTED: 'accepted', // proposal is accepted by homeowner
  FREEZED: 'freezed', // once homeowner accept a bid from installer then all other bids on listing will be freezed
  PENDING_PAYMENT: 'pending_payment',
  CONFIRM_PAYMENT: 'confirm_payment',
  SUCCESSFUL_PAYMENT: 'successful_payment',
  FAILED_PAYMENT: 'failed_payment',
  EXPIRED: 'expired', // proposal is expired automatically
  COMPLETED: 'completed', // after payment is done by installer
};

export const chatTypeEnum = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  OTHER: 'other',
  TRANSITION: 'transition',
};

export const brandOptions = [
  {
    key: 'all',
    value: 'all',
    label: 'All Brands',
  },
  {
    key: 'generac',
    value: 'Generac',
    label: 'Generac',
  },
  {
    key: 'kohler',
    value: 'Kohler',
    label: 'Kohler',
  },
  {
    key: 'briggsStratton',
    value: 'Briggs & Stratton',
    label: 'Briggs & Stratton',
  },
  {
    key: 'honeywell',
    value: 'HoneyWell',
    label: 'HoneyWell',
  },
  {
    key: 'champion',
    value: 'Champion',
    label: 'Champion',
  },
];

export const coolingTypes = [
  {
    key: 'both',
    value: 'both',
    label: 'Both',
  },
  {
    key: 'air',
    value: 'air',
    label: 'Air Cooled',
  },
  {
    key: 'liquid',
    value: 'liquid',
    label: 'Liquid Cooled',
  },
];

export const services = [
  {
    key: 'both',
    value: 'both',
    label: 'Both',
  },
  {
    key: 'sales',
    value: 'sales',
    label: 'Generator Sales',
  },
  {
    key: 'install',
    value: 'install',
    label: 'Install Only',
  },
];

export const squareFootage = [
  {
    key: 'tinyHome',
    value: '800-100',
    label: '800-100 sq/ft (Tiny home)',
  },
  {
    key: 'smallHome',
    value: '1000-1500',
    label: '1000-1500 sq/ft (Small home)',
  },
  {
    key: 'std2Bdrm',
    value: '1500-2000',
    label: '1500-2000 sq/ft (Standard for 2 bedroom)',
  },
  {
    key: 'std3Bdrm',
    value: '2000-2500',
    label: '2000-2500 sq/ft (Standard for 3 bedroom)',
  },
  {
    key: 'std4Bdrm',
    value: '2500-3500',
    label: '2500-3500 sq/ft (Standard for 4 bedroom)',
  },
  {
    key: 'largeHome',
    value: '3500-plus',
    label: '3500-plus sq/ft (Large home)',
  },
];

export const appliances = {
  partialCoverage: {
    utility: [
      { name: 'AC', count: 0 },
      { name: 'Furnance', count: 0 },
      { name: 'Water Pump', count: 0 },
      { name: 'Sump Pump', count: 0 },
      { name: 'Water Heater', count: 0 },
      { name: 'Wifi', count: 0 },
    ],
    houseHold: [
      { name: 'Room Light', count: 0 },
      { name: 'Computer', count: 0 },
      { name: 'Freezer', count: 0 },
      { name: 'TV', count: 0 },
      { name: 'Fan', count: 0 },
      { name: 'Pet Needs', count: 0 },
    ],
    appliance: [
      { name: 'Stove', count: 0 },
      { name: 'Dishwasher', count: 0 },
      { name: 'Fridge', count: 0 },
      { name: 'Small Appliance', count: 0 },
      { name: 'Security', count: 0 },
    ],
    outdoor: [
      { name: 'Pool', count: 0 },
      { name: 'Car Charger', count: 0 },
      { name: 'Garage Power', count: 0 },
      { name: 'Electric Fence', count: 0 },
      { name: 'Outbuilding', count: 0 },
      { name: 'Power Tools', count: 0 },
    ],
  },
  fullCoverage: {
    utility: [
      { name: 'AC', count: 1, min: 1 },
      { name: 'Furnance', count: 1, min: 1 },
      { name: 'Water Pump', count: 0 },
      { name: 'Sump Pump', count: 0 },
      { name: 'Water Heater', count: 0 },
      { name: 'Wifi', count: 1, min: 1 },
    ],
    houseHold: [
      { name: 'Room Light', count: 0 },
      { name: 'Computer', count: 0 },
      { name: 'Freezer', count: 0 },
      { name: 'TV', count: 0 },
      { name: 'Fan', count: 0 },
      { name: 'Pet Needs', count: 0 },
    ],
    appliance: [
      { name: 'Stove', count: 1, min: 1 },
      { name: 'Dishwasher', count: 1, min: 1 },
      { name: 'Fridge', count: 1, min: 1 },
      { name: 'Small Appliance', count: 1, min: 1 },
      { name: 'Security', count: 0 },
    ],
    outdoor: [
      { name: 'Pool', count: 0 },
      { name: 'Car Charger', count: 0 },
      { name: 'Garage Power', count: 0 },
      { name: 'Electric Fence', count: 0 },
      { name: 'Outbuilding', count: 0 },
      { name: 'Power Tools', count: 0 },
    ],
  },
};

export const soonOptions = [
  {
    key: 'urgent',
    value: 'urgent',
    label: 'Urgent ( as soon as possible)',
  },

  {
    key: 'two',
    value: 'Within 2 months',
    label: 'Within 2 months',
  },
  {
    key: 'three',
    value: 'Within 2-6 months',
    label: 'Within 2-6 months',
  },
  {
    key: 'flexible',
    value: 'flexible',
    label: 'Flexible (anytime)',
  },
];

export const reasonOptions = [
  {
    key: 'medical',
    value: 'Medical devices & needs',
    label: 'Medical devices & needs',
  },
  {
    key: 'infant',
    value: 'Infant & elderly family needs',
    label: 'Infant & elderly family needs',
  },
  {
    key: 'heating',
    value: 'Heating & cooling',
    label: 'Heating & cooling',
  },

  {
    key: 'water',
    value: 'Water access pumping & filtration',
    label: 'Water access pumping & filtration',
  },
  {
    key: 'livestock',
    value: 'Livestock needs',
    label: 'Livestock needs',
  },
  {
    key: 'security',
    value: 'Security',
    label: 'Security',
  },

  {
    key: 'work',
    value: 'Work & internet access',
    label: 'Work & internet access',
  },
];

export const planOptions = [
  {
    key: 'full',
    value: 'Pay in full',
    label: 'Pay in full',
  },
  {
    key: 'partial',
    value: 'Partial payment ( Deposit upfront & balance post install)',
    label: 'Partial payment ( Deposit upfront & balance post install)',
  },
  {
    key: 'financing',
    value: 'Financing option',
    label: 'Financing option',
  },
];

export const generatorOptions = [
  {
    key: 'mySelf',
    value: 'mySelf',
    label: 'Myself',
  },
  {
    key: 'parents',
    value: 'parents',
    label: 'Parent(s)',
  },
  {
    key: 'friend',
    value: 'friend',
    label: 'Friend',
  },
  {
    key: 'company',
    value: 'company',
    label: 'Company',
  },
];

export const brandOptionsIRF = [
  {
    key: 'any',
    value: 'any',
    label: 'Any',
  },
  {
    key: 'generac',
    value: 'generac',
    label: 'Generac',
  },
  {
    key: 'kohler',
    value: 'kohler',
    label: 'Kohler',
  },
  {
    key: 'briggsStratton',
    value: 'briggs',
    label: 'Briggs & Stratton',
  },
  {
    key: 'honeywell',
    value: 'honeyWell',
    label: 'Honeywell',
  },
  {
    key: 'champion',
    value: 'champion',
    label: 'Champion',
  },
];

export const brand2 = [
  {
    label: 'Generac',
  },
  {
    label: 'Kohler',
  },
  {
    label: 'Briggs & Stratton',
  },
  {
    label: 'Honeywell',
  },
  {
    label: 'Champion',
  },
  {
    label: 'N/A',
  },
];

export const kilowattSize = [
  {
    label: '10KW',
  },
  {
    label: '12KW',
  },
  {
    label: '14KW',
  },
  {
    label: '16KW',
  },
  {
    label: '18KW',
  },
  {
    label: '20KW',
  },
  {
    label: '22KW',
  },
  {
    label: '24KW',
  },
  {
    label: '26KW',
  },
  {
    label: '30KW',
  },
  {
    label: '48KW',
  },
  {
    label: '60KW',
  },
  {
    label: 'N/A',
  },
];
export const agreeOption = [
  {
    label: 'Yes',
  },
  {
    label: 'No',
  },
];

export const generatorMounting = [
  {
    label: 'ConcretePad',
  },
  {
    label: 'Platform',
  },
  {
    label: 'Shelfstructure',
  },
  {
    label: 'N/A',
  },
];

export const warranty = [
  {
    label: 'Company',
  },
  {
    label: 'Maintenance',
  },
  {
    label: 'Manufacturer',
  },
  {
    label: 'N/A',
  },
];

export const feetDropDown = [
  {
    label: '5ft',
  },
  {
    label: '10ft',
  },
  {
    label: '15ft',
  },
  {
    label: '20ft',
  },
  {
    label: '25ft',
  },
  {
    label: '30ft',
  },
  {
    label: '35ft',
  },
  {
    label: '40ft',
  },
  {
    label: '45ft',
  },
  {
    label: '50ft',
  },
  {
    label: '55ft',
  },
  {
    label: '60ft',
  },
  {
    label: '65ft',
  },
  {
    label: '70ft',
  },
  {
    label: '75ft',
  },
  {
    label: 'N/A',
  },
];
export const switchAmpSize = [
  {
    label: '100Amp',
  },
  {
    label: '150Amp',
  },
  {
    label: '200Amp',
  },
  {
    label: '300Amp',
  },
  {
    label: '400 Amp',
  },
  {
    label: '600Amp',
  },
  {
    label: '800Amp',
  },
  {
    label: '1000Amp',
  },
  {
    label: 'N/A',
  },
];

export const budgetOptions = [
  {
    key: 'budget1',
    value: '$5,000 to $10,000',
    label: 'Budget: $5,000 to $10,000',
    noOfVolts: 2,
  },
  {
    key: 'budget2',
    value: '10,000 to 15,000',
    label: 'Budget: $10,000 to $15,000',
    noOfVolts: 4,
  },
  {
    key: 'budget3',
    value: '15,000 to $20,000',
    label: 'Budget: $15,000 to $20,000',
    noOfVolts: 6,
  },
  {
    key: 'budget4',
    value: '20,000 to $25,000',
    label: 'Budget: $20,000 to $25,000',
    noOfVolts: 8,
  },
  {
    key: 'budget5',
    value: '25,000 and up',
    label: 'Budget: $25,000 and up',
    noOfVolts: 10,
  },
];

export const fuelSourceOptions = [
  {
    key: 'gas',
    value: 'gas',
    label: 'Natural Gas',
  },
  {
    key: 'propane',
    value: 'propane',
    label: 'Propane',
  },
  {
    key: 'electric',
    value: 'electric',
    label: 'All Electric',
  },
];

export const generatorTypes = [
  {
    key: 'air',
    value: 'air',
    label: 'Air-Cooled (Most homes)',
  },
  {
    key: 'liquid',
    value: 'liquid',
    label: 'Liquid-Cooled (add for larger homes & farms)',
  },
];

export const carouselItems = [
  {
    key: 'generac',
    image: generac,
    imageTitle: generacLogo,
    title: 'American Made | EST 1959',
    description:
      'Generac has been making generators since 1959 and they have 70% of the market share and the #1 selling brand of stand-by home generators manufactured in Wisconsin. Offering Dual-Fuel generators to suit your home needs. Some key features include:',
    details: [
      'Remote Monitoring: ability to monitor generator from your smartphone or PC.',
      'Auto Transfer Switch: seamless transition from utility power to generator power.',
      'Power Management: generator provides necessary power without overloading the system, preventing damage to appliances',
      'Quiet-Test Mode: run weekly self-test of the generator.	',
      'Air Cooled sizing -7kw-26kw	',
      'Liquid Cooled sizing -22kw-150kw',
    ],
  },
  {
    key: 'briggs',
    image: briggs,
    imageTitle: briggsLogo,
    title: 'American Made | EST 1925',
    description:
      'Briggs and Stratton have been making many different engines including generators since 1925. Based in Wisconsin, the motors are American made and have a long history of reliability. They have a much smaller footprint in the market, but they tend to be a less expensive option. Some key features are:',
    details: [
      'Patented Symphony ll Power Management: capable of managing the power for high-demand appliances',
      'Versatile: will work with both natural gas and liquid propane',
      'Quiet Sound dampening technology makes this model 50% quieter than portable generators',
      'Air Cooled sizing ~ 10kw-26kw',
      'Liquid Cooled sizing ~ 30kw-150kw',
    ],
  },
  {
    key: 'champion',
    image: champion,
    imageTitle: championLogo,
    title: 'American Made | EST 2003',
    description:
      'Champion generators was founded in 2003 in Santa Fe, California. Champion is a great option for a smaller home without a lot of high demand like electric heat and central air. Great option for a smaller budget. Some key features are:',
    details: [
      'Varying Climate: Can operate in temperatures ranging from -22 Degree F to 104 Degree F',
      'Tough Protection: A steel enclosure protects your generator from the elements.',
      'Manufacturer Warranty: 10-year limited warranty and free lifetime technical support	',
      'Air Cooled sizing - 8.5kw-14kw',
    ],
  },

  {
    key: 'honeyWell',
    image: honeyWell,
    imageTitle: honeyWellLogo,
    title: 'American Made | EST 1906',
    description:
      'Honeywell was acquired by Generac in 2011 and now have Generac motors. A noticeable difference between the two would be noise level. The Honeywell operates at 85db where as Generac operates at 66db (your typical A/C is 68db). Some key features are:',
    details: [
      'Remote Monitoring: ability to monitor your generator with smartphone or PC.',
      'Protection: Rhino lined weather proof case.	',
      'Automatic Transfer switch: senses loss of power.',
      'Auto-test: Weekly self test.',
      'Made with the reliable American made Generac motor.	',
      'Air Cooled sizing - 8kw-26kw	',
      'Liquid Cooled sizing - 27kw-150kw',
    ],
  },

  {
    key: 'kohler',
    image: kohler,
    imageTitle: kohlerLogo,
    title: 'American Made | EST 1920',
    description:
      'Kohler was the first to offer generators in 1920. Made in Wisconsin, Kohler is known for it’s high-end products and innovative technology, offering homeowners a top-of-the-line power backup solution. Some key features offered by Kohler include:',
    details: [
      'Remote monitoring: receive alerts, monitor usage & control generator.',
      'Auto Transfer Switch: seamless transition from utility to generator power in outage.',
      'Power Management: prioritize use of your generator’s power to essential appliances.',
      'Sound Attenuation: Kohler’s enclosures reduce the noise of the generator.',
      'Air Cooled sizes -6kw-24kw',
      'Liquid Cooled sizes -24kw-48kw',
    ],
  },
];

export const coverage = [
  {
    key: '10KW',
    label: '10 KW | partial coverage only',
    price: '$3000- 4200',
  },

  {
    key: '14KW',
    label: '14 KW | up to 2000 sq/ft home',
    price: '$4200-5000',
  },
  {
    key: '18KW',
    label: 'up to 3000 sq/ft home',
    price: '$4800- 6000',
  },
  {
    key: '20 KW',
    label: 'up to 3500 sq/ft home',
    price: '$5000-6000',
  },
  {
    key: '22 KW',
    label: 'up to 4000 sq/ft home',
    price: '$5300 -6200 (200 amp Transfer Switch)',
  },
  {
    key: '24 KW',
    label: '4000+ sq/ft home ',
    price: '$5700-6500 (200 amp Transfer Switch)',
  },
  {
    key: '26 KW ',
    label: '4000+ sq/ft home',
    price: '$6200-7200 (200 amp Transfer Switch)',
  },
  {
    key: '30KW ',
    label: 'Light Commercial size ',
    price: '$12,000-14,000',
  },
  {
    key: '38KW',
    label: 'Light Commercial size ',
    price: '$15,000-16,500',
  },
  {
    key: '60KW',
    label: 'Light Commercial size ',
    price: ' $19,000-23,000',
  },
];

export const experienceOptions = (numberOfYears => {
  const options = [];
  const currentYear = new Date().getFullYear();

  for (let i = 0; i <= numberOfYears; i++) {
    options.push({
      value: i,
      key: i.toString(),
      label: `${currentYear - i} (${i} year${i > 1 ? 's' : ''} of experience)`,
    });
  }
  return options;
})(20);

export const variableCost = [
  {
    key: 'switch',
    label: 'Size of Transfer Switch',
    price: '$599-$3,000',
  },
  {
    key: 'electrical',
    label: 'Electrical service upgrade',
    price: '$1,200-$3,000',
  },
  {
    key: 'fuelSource',
    label: 'LP tank-size dependent',
    price: '$1,200-$4,000',
  },
  {
    key: 'lpg',
    label: 'LP gas hookup',
    price: '$1,600-$2500',
  },
  {
    key: 'cng',
    label: 'Natural gas hookup',
    price: '$1,600-$2500',
  },
  {
    key: 'installation',
    label: 'Installation site prep',
    price: '$300-$1,000',
  },
  {
    key: 'cable',
    label: 'Extra Electrical cable run',
    price: '$5–$35 /ft',
  },
  {
    key: 'permits',
    label: 'Municipal Permits',
    price: 'Check with your local county',
  },
  {
    key: 'warranty',
    label: 'Warranty Maintenance Plans',
    price: 'Plans & Price vary'
  },
  {
    key: 'delivery',
    label: 'Delivery Fee / Trip Charge',
    price: 'Vary by installer'
  },
];

export const packages = [
  {
    _id: '643698aa68303336ee4cbe77',
    amount: 0,
    name: 'Low Volt Package',
    noOfVolts: 7,
  },
  {
    _id: '64369c0c68303336ee4cbe78',
    amount: 0,
    name: 'Medium Volt Package',
    noOfVolts: 14,
  },
  {
    _id: '64369c6568303336ee4cbe7b',
    amount: 10,
    name: 'High Volt Package',
    noOfVolts: 21,
  },
];
export const photoLabel = [
  {
    key: 'd1',
    label: 'Video or photo of the',
    value: 'Electric Panel',
  },

  {
    key: 'd2',
    label: 'Video or photo of the',
    value: 'Preffered Generator type',
  },
  {
    key: 'd3',
    label: 'Video or photo of the',
    value: 'Fuel Source',
  },
  {
    key: 'd4',
    label: 'Video or photo of the',
    value: 'House',
  },
];
