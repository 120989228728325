import React, { Component, useState } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { withViewport } from '../../util/uiHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Avatar,
  Button,
  IconVolts,
  InlineTextButton,
  LimitedAccessBanner,
  LinkedLogo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  Modal,
  ModalMissingInformation,
  NamedLink,
  NotificationBadge,
} from '../../components';

import SearchIcon from './SearchIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.css';
import { ensureCurrentUser } from '../../util/data';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import IconCollection from '../IconCollection/IconCollection';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {

    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds, region, country } = selectedPlace;

      return {
        query: [region, country].filter(e => e).join(', ')
      };
    };

    history.push(
      createResourceLocatorString(
        'AlgoliaSearchPage',
        routeConfiguration,
        {},
        topbarSearchParams()
      )
    );
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      hideSearchBar,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config,
    } = this.props;

    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <NotificationBadge count={notificationCount} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
      />
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();
    const user = ensureCurrentUser(currentUser);
    const isAuthenticatedOrJustHydrated = isAuthenticated;
    const classes = classNames(rootClassName || css.root, className);
    const { userType } = user.attributes.profile.publicData || {};
    const currentPageClass = page => {
      const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
      return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
    };
    const isInstaller = userType === 'IN';
    const isHomeOwner = userType === 'HO';
    const voltsBalanceLink =
      isAuthenticatedOrJustHydrated && isInstaller ? (
        <NamedLink name="PurchaseAcornsPage" className={css.voltsBalanceLink}>
          <span className={css.signup}>
            <IconVolts />
            <FormattedMessage
              id="TopbarDesktop.volts"
              values={{ balance: currentUser?.attributes.profile.publicData?.voltsBalance || 0 }}
            />
          </span>
        </NamedLink>
      ) : null;
    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>

          <div>
            <LinkedLogo format={'mobile'} alt={intl.formatMessage({ id: 'Topbar.logoIcon' })} />
            {isInstaller && <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <SearchIcon className={css.searchMenuIcon} />
            </Button>}
          </div>
          <div>
            {voltsBalanceLink}

            {isAuthenticated ? (<Menu>
              <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
                <Avatar className={css.menuIcon} user={currentUser} disableProfileLink />
              </MenuLabel>
              <MenuContent className={css.profileMenuContent} arrowPosition={10} style={{ right: true }}>
                <MenuItem key="InboxPage">
                  <NamedLink
                    className={classNames(
                      css.profileSettingsLink,
                      currentPageClass('InboxPage')
                    )}
                    name="InboxPage"
                    params={{ status: 'pending' }}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.inbox" />
                    {notificationDot}
                  </NamedLink>
                </MenuItem>
                {
                  isHomeOwner && (
                    <MenuItem key="NewListingPage">
                      <NamedLink
                        className={classNames(
                          css.profileSettingsLink,
                          currentPageClass('NewListingPage')
                        )}
                        name="NewListingPage"
                      >
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.createListing" />
                      </NamedLink>
                    </MenuItem>
                  )
                }
                {isHomeOwner && (
                  <MenuItem key="ManageListingsPage">
                    <NamedLink
                      className={classNames(
                        css.yourListingsLink,
                        currentPageClass('ManageListingsPage')
                      )}
                      name="ManageListingsPage"
                    >
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.yourListingsLink" />
                    </NamedLink>
                  </MenuItem>
                )}

                <MenuItem key="ProfileSettingsPage">
                  <NamedLink
                    className={classNames(
                      css.profileSettingsLink,
                      currentPageClass('ProfileSettingsPage')
                    )}
                    name="ProfileSettingsPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="AccountSettingsPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('AccountSettingsPage')
                    )}
                    name="AccountSettingsPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="logout">
                  <InlineTextButton rootClassName={css.logoutButton} onClick={this.handleLogout}>
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.logout" />
                  </InlineTextButton>
                </MenuItem>
              </MenuContent>
            </Menu>
            ) : <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <IconCollection name="HAMBURGER_MENU" />
              {notificationDot}
            </Button>}
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            appConfig={config}
            hideSearchBar={hideSearchBar}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          className={css.loginModal}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedTopbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

const Topbar = withViewport(EnhancedTopbar);
Topbar.displayName = 'Topbar';

export default Topbar;
