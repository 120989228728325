import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVolts.module.css';

const IconVolts = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2 12.5H19L8.8 26V15.5045L2 15.5L12.2 2V12.5Z"
        stroke="#00C3A0"
        fill="none"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconVolts.defaultProps = {
  className: null,
  rootClassName: null,
};

IconVolts.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVolts;
