import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useConfiguration } from '../../context/configurationContext';
import { useSelector } from 'react-redux';
const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl } = props;
  const config = useConfiguration();
  const { currentUser } = useSelector(state => state.user);
  const { userType } = currentUser.attributes?.profile?.publicData || {};
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  const marketplaceFee = userType === 'IN' && formatMoney(intl, new Money(totalPrice.amount / 20, config.currency));

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
      {
        userType === 'IN' && (<>
          <hr className={css.totalDivider} />
          <div className={css.lineItemTotal}>
            <div className={css.totalLabel}>One time Platform Fee</div>
            <div className={css.totalPrice}>{marketplaceFee}</div>
          </div>
          <hr className={css.totalDivider} />
          <div className={css.lineItemTotal}>
            <div className={css.totalLabel}>Payment Total</div>
            <div className={css.totalPrice}>{marketplaceFee}</div>
          </div>
        </>)
      }
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
