import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const EyeIcons = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName, className);

  switch (type) {
    case 'CLOSE':
      return (
        <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="eye">
          <path
            fill="none"
            fillRule="evenodd"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24M1 1l22 22"
          ></path>
        </svg>
      );

    case 'OPEN':
      return (
        <svg
          className={classes}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          enableBackground="new 0 0 512 512"
          viewBox="0 0 512 512"
          id="eye"
        >
          <path
            d="M256,406c-30.6,0-62.2-7.6-94-22.5c-24.9-11.7-50-27.9-74.6-48.2C45.9,301,20.3,267,19.2,265.6
			c-4.3-5.7-4.3-13.5,0-19.2c1.1-1.4,26.7-35.4,68.2-69.7c24.6-20.3,49.7-36.5,74.6-48.2c31.8-14.9,63.4-22.5,94-22.5
			s62.2,7.6,94,22.5c24.9,11.7,50,27.9,74.6,48.2c41.5,34.3,67.2,68.3,68.2,69.7c4.3,5.7,4.3,13.5,0,19.2
			c-1.1,1.4-26.7,35.4-68.2,69.7c-24.6,20.3-49.7,36.5-74.6,48.2C318.2,398.4,286.6,406,256,406z M52.6,256
			c25.1,29.7,108,118,203.4,118c95.6,0,178.3-88.3,203.4-118c-25.1-29.7-108-118-203.4-118C160.4,138,77.7,226.3,52.6,256z"
          ></path>
          <path
            d="M256,328c-39.7,0-72-32.3-72-72s32.3-72,72-72s72,32.3,72,72S295.7,328,256,328z M256,216
			c-22.1,0-40,17.9-40,40s17.9,40,40,40s40-17.9,40-40S278.1,216,256,216z"
          ></path>
        </svg>
      );

    default:
      return null;
  }
};

const { string } = PropTypes;

EyeIcons.defaultProps = {
  className: null,
  rootClassName: null,
};

EyeIcons.propTypes = {
  className: string,
  rootClassName: string,
};

export default EyeIcons;
