import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';

// ================ Action types ================ //

export const ADMIN_LISTING_SUCCESS = 'app/PurchaseAcornsPage/ADMIN_LISTING_SUCCESS';
export const ADMIN_LISTING_ERROR = 'app/PurchaseAcornsPage/ADMIN_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  adminListingIds: [],
  adminListingError: false,
};
const resultIds = data => data.data.map(l => l.id);

export default function PurchaseAcornsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_LISTING_SUCCESS:
      return { ...state, adminListingIds: resultIds(payload.data), adminListingError: false };
    case ADMIN_LISTING_ERROR:
      return { ...state, adminListingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ /

const adminListingSuccess = response => ({
  type: ADMIN_LISTING_SUCCESS,
  payload: response,
});

const adminListingError = error => ({
  type: ADMIN_LISTING_ERROR,
  payload: error,
});

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      pub_ADMIN: true,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(adminListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(adminListingError(storableError(e)));
      throw e;
    });
};
