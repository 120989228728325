import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { sendInvite } from '../../util/api';

export const ADD_TECH_REQUEST = 'app/tech/ADD_TECH_REQUEST';
export const ADD_TECH_SUCCESS = 'app/tech/ADD_TECH_SUCCESS';
export const ADD_TECH_ERROR = 'app/tech/ADD_TECH_ERROR';

const initialState = {
  addTechInProgress: false,
  addTechError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TECH_REQUEST:
      return { ...state, addTechInProgress: payload, addTechError: null };
    case ADD_TECH_ERROR:
      return { ...state, addTechError: payload, addTechInProgress: false };

    default:
      return state;
  }
}

const addTechRequest = payload => ({ type: ADD_TECH_REQUEST, payload });
const addTechError = payload => ({ type: ADD_TECH_ERROR, payload });

export const addTechToCompany = params => (dispatch, getState, sdk) => {
  const { email, members, currentPassword, company } = params;

  dispatch(addTechRequest(true));

  const { currentUser, currentUserListing } = getState().user;

  return sdk
    .login({ username: email, password: currentPassword })
    .then(() => {
      return sendInvite({
        members,
        company,
        id: currentUser.id,
        installerId: currentUserListing.id,
      });
    })
    .then(res => {
      dispatch(addTechRequest(false));
      return dispatch(fetchCurrentUser());
    })
    .catch(e => {
      dispatch(addTechError(storableError(e)));
    });
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
