import React, { useState } from 'react';
import Slider from 'react-slick';
import { IconVolts, SecondaryButton } from '../../components';

import css from './AcornsCard.module.css';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { ensureListing } from '../../util/data';
import { updateVoltWallet } from '../../util/api';

const AcornsCard = props => {
  const { user, packages, onSubmit } = props;

  const [purchaseInProgress, setProgress] = useState(null);
  const dispatch = useDispatch();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrow: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        }
      }
    ]
  };

  const handlePurchase = pack => {
    const { title, publicData = {} } = pack.attributes;
    const { price, noOfVolts } = publicData;

    if (price && price.amount <= 0) {
      setProgress(pack.id?.uuid);
      return Promise.all([
        dispatch(
          updateProfile({
            publicData: {
              voltsBalance:
                Number(user.attributes.profile.publicData?.voltsBalance || 0) + noOfVolts,
            },
          })
        ),
        updateVoltWallet({
          userId: user.id.uuid,
          amount: noOfVolts,
          status: 'cr',
          description: title,
        }),
      ]).then(() => setProgress(null));
    }
    onSubmit(pack);
  };

  return (
    <div className={css.contentMain}>
      <Slider {...settings}>
        {packages
          .sort((a, b) => a.attributes.publicData?.id - b.attributes.publicData?.id)
          .map((pack, i) => {
            const ensuredPack = ensureListing(pack);
            const {
              title,
              publicData: {
                price: { amount },
                noOfVolts,
              },
            } = ensuredPack.attributes;
            return (
              <div className={css.card} key={title + i}>
                <h3 className={css.packageName}>{title}</h3>
                <div className={css.packagePrice}>
                  <span className={css.freeText}>${amount || 'FREE'}</span>
                  <span className={css.voltNo}>
                    | {noOfVolts} Volts <IconVolts className={css.iconVolts} />{' '}
                  </span>
                </div>
                <SecondaryButton
                  className={css.purchaseButton}
                  inProgress={purchaseInProgress === pack.id?.uuid}
                  onClick={() => handlePurchase(ensuredPack)}
                >
                  Purchase
                </SecondaryButton>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default AcornsCard;
