import pick from 'lodash/pick';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { createPaymentIntentApi, findOrCreateOrder, updateOrder } from '../../util/api';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { showListing } from '../ListingPage/ListingPage.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPageDB/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPageDB/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPageDB/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPageDB/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPageDB/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPageDB/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPageDB/CONFIRM_PAYMENT_ERROR';

export const CREATE_PI_REQUEST = 'app/CheckoutPageDB/CREATE_PI_REQUEST';
export const CREATE_PI_SUCCESS = 'app/CheckoutPageDB/CREATE_PI_SUCCESS';
export const CREATE_PI_ERROR = 'app/CheckoutPageDB/CREATE_PI_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPageDB/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPageDB/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPageDB/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  createPaymentIntentInProgress: false,
  createPaymentIntentError: null,
  paymentIntent: null,
  transaction: null,
  initiateOrderError: null,
  initiateOrderInProgress: false,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
};

const { UUID } = sdkTypes;

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case CREATE_PI_REQUEST:
      return {
        ...state,
        createPaymentIntentInProgress: true,
        createPaymentIntentError: null,
        paymentIntent: null,
      };
    case CREATE_PI_SUCCESS:
      return {
        ...state,
        createPaymentIntentInProgress: false,
        paymentIntent: payload.transaction,
      };
    case CREATE_PI_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        createPaymentIntentInProgress: false,
        createPaymentIntentError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null, initiateOrderInProgress: true };
    case INITIATE_ORDER_SUCCESS:
      return {
        ...state,
        ...payload,
        orderData: payload.transaction.bidResponse,
        initiateOrderInProgress: false,
      };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload, initiateOrderInProgress: false };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = orderResponse => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: orderResponse,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const createPaymentIntentRequest = () => ({ type: CREATE_PI_REQUEST });

export const createPaymentIntentSuccess = transaction => ({
  type: CREATE_PI_SUCCESS,
  payload: { transaction },
});

export const createPaymentIntentError = e => ({
  type: CREATE_PI_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (params, config) => async (dispatch, getState) => {
  dispatch(initiateOrderRequest());

  const { id: transactionId, fetchListing = true } = params;

  let orderResponse = null;
  let listingRef = null;

  return findOrCreateOrder({ transactionId })
    .then(transaction => {
      orderResponse = { transaction };
      listingRef = new UUID(transaction.listingId);
      Object.assign(orderResponse.transaction, { listingRef });
      return dispatch(showListing(listingRef, config));
    })
    .then(() => {
      const listing = getListingsById(getState(), [listingRef])[0];
      Object.assign(orderResponse, { listing });
      dispatch(initiateOrderSuccess(orderResponse));
      return orderResponse;
    })
    .catch(e => {
      console.log(e);
      dispatch(initiateOrderError(storableError(e)));
    });
};

export const confirmPayment = params => dispatch => {
  dispatch(confirmPaymentRequest());

  return updateOrder(params)
    .then(transaction => dispatch(confirmPaymentSuccess(transaction._id)))
    .catch(e => dispatch(confirmPaymentError(storableError(e))));
};

export const createPaymentIntent = params => dispatch => {
  dispatch(createPaymentIntentRequest());

  return createPaymentIntentApi(params)
    .then(transaction => {
      dispatch(createPaymentIntentSuccess(transaction));
      return transaction;
    })
    .catch(e => dispatch(createPaymentIntentError(e)));
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => dispatch => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const loadData = (params, search, config) => (dispatch, getState) => {
  const { id } = params;
  if (!id) return Promise.reject('Id cannot be null');
  return dispatch(initiateOrder(params, config));
};
