import React, { useEffect, useState } from 'react';
import css from './ToolTip.module.css';
import InfoIcon from './InfoIcon';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import classNames from 'classnames';

const ToolTip = ({ text, toolTipTextClass = '', tooltipClass = '', open = false }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => setOpen(open), [open]);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={classNames(css.tooltip, tooltipClass)}>
        <span onClick={e => [e.preventDefault(), setOpen(!isOpen)]}>
          <InfoIcon />
        </span>
        {isOpen && (
          <span className={classNames(css.tooltipText, toolTipTextClass)}>
            <InfoIcon />
            {text}
          </span>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default ToolTip;
