import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';


import { intlShape, injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';

import { NamedLink } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const ListingCardComponent = props => {
  const { className, rootClassName, hit } = props;

  const classes = classNames(rootClassName || css.root, className);
  const { objectID: id, title, pictureFields = [] } = hit;

  const slug = createSlug(title);
  const url = (pictureFields.find(p => p.category === "House" && p.imageURL) || pictureFields.find(p => p.imageURL))?.imageURL;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <img src={url} />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.price}>
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
